<template>
  <div id="newsStoryContent">
    <p id="Text1">
      In January, we conducted an epic new “<router-link :to="{ name: 'story', params: {id: 272475} }">Build a Rocket</router-link>” Contest, which gave the chance to see your own Rocket design in LEGO Universe! We received amazing designs from hopeful builders, ranging from sleek, aerodynamic rockets to a mouth-watering delectable Hot Dog Rocket with all the trimmings! With such amazing entries, picking just one Grand Prize winner was a daunting task, but in the end a decision had to be made! The results of the “Build a Rocket” Contest are in!
    </p>
    <p class="center">
      <!-- picE0ADBA7FF6D1C7640EB0EA4B77C44477.jpg -->
      <img src="@/assets/news-network/contest-rocket.jpg" class="rounded">
    </p>
    <p>
      Out of over 600 amazing designs, we are proud to announce the grand prize winner! Originally named by Gotheria as the “Muscle Car Rocket,” the winning rocket has been optimized and renamed to the “Musclenaut GT Rocket”! This sweet ride actually has a power plant installed, which absorbs Maelstrom energy and converts it to fuel! You’ll never run out of juice with this smart Maelstrom Conversion Engine!
    </p>
    <p class="center">
      <!-- picE87D4051FD83D0C58253808C01D199F7.jpg -->
      <img src="@/assets/news-network/contest-rocket-2.jpg" class="rounded">
    </p>
    <p>
      There was just one problem... Gotheria’s design was only a prototype. Never fear, our LEGO Universe Professional Builders are here! In order for this super sweet Rocket to fit seamlessly into LEGO Universe, our Professional Builder, Duane Hess, had to make some modifications to the build design. To ensure smooth performance for all minifigures, Gotheria’s Rocket had to reach certain polygon goals.
    </p>
    <p>
      Gotheria’s Original Muscle Car Rocket:
    </p>
    <p class="center">
      <!-- pic0ECFD08FE53F87EA5B5AE0332BEC6CAB.jpg -->
      <img src="@/assets/news-network/contest-rocket-3.jpg" class="rounded">
    </p>
    <p class="center">
      <!-- pic42B15DEBB1CD768210351E587C5BF986.jpg -->
      <img src="@/assets/news-network/contest-rocket-4.jpg" class="rounded">
    </p>
    <p>The redesigned Musclenaut:</p>
    <p align="center">
      <!-- pic5037118F2B1C00B8F51078582628686B.jpg -->
      <img src="@/assets/news-network/contest-rocket-5.jpg" class="rounded">
    </p>
    <p>
      What are polygons and why should you care? Well, everything you see in LEGO Universe is made up of hundreds of polygons or triangles – each angle and shape. Polygons allow for greater detail and visual pizzazz, but the higher the polygon count, the longer it takes to render, or load. The lower the polygon count, the faster it is to load that item – however it is a fine balance to reduce the polygon count while keeping things cool and fresh!
    </p>
    <p>
      With that in mind, Duane Hess was put to the tremendous task to reduce the polygon count for the Musclenaut GT Rocket. The original design started out at a daunting 31,660 polygon count! Our professional builder took Gotheria’s design apart, brick by brick, studying and examining the design. His goal was to keep the intent of the original feel of a sweet muscle car rocket intact, but substituting parts that have less angles and knobs poking out, so you have a smoother, faster journey when rocketing off to different worlds. After carefully redesigning the rocket module parts, (the Nose Cone Module took 16 tries!), he was able to shave it down to a cool 11,800 polygon count!
    </p>
    <div class="insert">
      <p>
        <!--  deal="" whole="" the="" engine="" middle="" nosecone="" 4="" and="" before="" columns="" 2="" -->
        The Musclenaut GT Rocket is a very special Rocket. This new Rocket is the culmination of your creative minds combined with the know-how of our professional builders. You can purchase this iconic piece of LEGO Universe history from Ace Warprider in Nexus Tower tomorrow, when the Power of the Nexus Force update releases! Remember that the Musclenaut GT Rocket is a pure indestructible muscle Rocket and if deleted will <b>not</b> turn into bricks in your Backpack. You wouldn’t want to delete such a cool ride anyways!
      </p>
      <p>
        Do you want to see your own design in LEGO Universe? Your next chance is here! Polish up your thinking cap and start designing your very own Pet design for our Create a Pet contest! You can read full details <router-link :to="{ name: 'story', params: {id: 342862} }">here</router-link>!
      </p>
      <p>
        Discuss this article and the Musclenaut GT Rocket on our
        <router-link to="/messageboards/4092804">message boards</router-link>! Rocket and roll!
      </p>
    </div>
  </div>
</template>
